import { Box, Container, Typography, Grid } from "@mui/material";
export function AboutUsSection() {
  return (
    <Box sx={{ py: 6, backgroundColor: "#f9f9f9" }}>
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h4" sx={{ fontWeight: "bold", mb: 4 }}>
              About Us
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
              We are a community-driven platform that connects generous donors
              with families in need. Our mission is to give a second life to
              unused items and provide essential goods to those who need them
              most.
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Whether it’s clothing, furniture, or other household items, we aim
              to make the donation process easy and impactful.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src="/path/to/about-us-image.jpg"
              alt="About Us"
              sx={{ width: "100%", borderRadius: 2 }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

import React, { useState, useEffect, useContext } from "react";
import { supabase } from "../../supabaseClient";
import { Box, Typography, Button } from "@mui/material";
import RequestTable from "../../components/Admin/RequestTable";
import ScheduleDialog from "../../components/Admin/ScheduleDialog";
import { AuthContext } from "../../context/AuthContext";

const AdminDashboard = () => {
  const { user } = useContext(AuthContext);
  const [requests, setRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [groupBy, setGroupBy] = useState("buyer"); // or "donor"
  const [groupedRequests, setGroupedRequests] = useState({});
  const [groupedByBuyer, setGroupedByBuyer] = useState({});
  const [pickupTime, setPickupTime] = useState("");
  const [dropoffTime, setDropoffTime] = useState("");
  const [driver, setDriver] = useState("");
  const [drivers, setDrivers] = useState([]);

  useEffect(() => {
    fetchRequests();
    fetchDrivers();
  }, []);

  const fetchRequests = async () => {
    try {
      const { data: requestsData, error } = await supabase
        .from("requests")
        .select(
          `
          *,
          buyer:buyer_id (
            id, 
            first_name, 
            last_name, 
            email, 
            profiles (address)
          ),
          donor:donor_id (
            id, 
            first_name, 
            last_name, 
            email, 
            profiles (address)
          ),
          items (
            id,
            title, 
            description, 
            category, 
            condition
          ),
          cart:cart_id (
            id,
            created_at
          )
        `
        )
        .eq("status", "pending");

      if (error) {
        console.error("Error fetching requests:", error.message);
        return;
      }

      // Group requests by buyer_id and then by cart_id
      const groupedByBuyerId = requestsData.reduce((acc, req) => {
        const buyerId = req.buyer_id;
        if (!acc[buyerId]) {
          acc[buyerId] = {
            buyerInfo: req.buyer,
            cartGroups: {},
          };
        }

        // Group by cart_id, or use requested_at if cart_id is null
        const groupingKey = req.cart_id || req.requested_at;
        if (!acc[buyerId].cartGroups[groupingKey]) {
          acc[buyerId].cartGroups[groupingKey] = {
            timestamp: req.cart?.created_at || req.requested_at,
            requests: [],
          };
        }
        acc[buyerId].cartGroups[groupingKey].requests.push(req);
        return acc;
      }, {});

      // Group requests by donor_id
      const groupedByDonorId = requestsData.reduce((acc, req) => {
        const donorId = req.donor_id;
        if (!acc[donorId]) {
          acc[donorId] = {
            donorInfo: req.donor,
            requests: [],
          };
        }
        acc[donorId].requests.push(req);
        return acc;
      }, {});

      setRequests(requestsData);
      setGroupedRequests(groupedByDonorId);
      setGroupedByBuyer(groupedByBuyerId);
    } catch (err) {
      console.error("Fetch Requests Error:", err.message);
    }
  };

  const fetchDrivers = async () => {
    try {
      const { data, error } = await supabase
        .from("drivers")
        .select(
          "id, first_name, last_name, vehicle_type, available_days, available_times"
        );

      if (error) {
        console.error("Error fetching drivers:", error.message);
        return;
      }

      setDrivers(data);
    } catch (err) {
      console.error("Fetch Drivers Error:", err.message);
    }
  };

  const handleSchedule = async (selectedRequestIds) => {
    try {
      console.log(
        "Starting scheduling process for requests:",
        selectedRequestIds
      );

      if (!driver || !pickupTime || !dropoffTime) {
        alert(
          "Please fill in all required fields (driver, pickup time, and dropoff time)"
        );
        return;
      }

      // Get the requests to get item_id, buyer_id, and donor_id
      const { data: requestsData, error: requestsError } = await supabase
        .from("requests")
        .select(
          `
          id,
          item_id,
          buyer_id,
          donor_id,
          items (id)
        `
        )
        .in("id", selectedRequestIds);

      if (requestsError) {
        console.error("Error fetching request data:", requestsError);
        throw requestsError;
      }

      console.log("Retrieved request data:", requestsData);

      // Validate that we have all necessary data
      if (!requestsData || requestsData.length === 0) {
        throw new Error("No request data found");
      }

      // Create orders for all selected requests
      const orders = requestsData.map((request) => {
        if (
          !request.id ||
          !request.item_id ||
          !request.buyer_id ||
          !request.donor_id
        ) {
          console.error("Missing required data for request:", request);
          throw new Error("Missing required data for order creation");
        }

        return {
          request_id: request.id,
          item_id: request.item_id,
          buyer_id: request.buyer_id,
          donor_id: request.donor_id,
          admin_id: user.id,
          assigned_driver: driver,
          pickup_time: pickupTime,
          dropoff_time: dropoffTime,
          status: "scheduled",
        };
      });

      console.log("Creating orders with data:", orders);

      // Insert orders
      const { data: insertedOrders, error: orderError } = await supabase
        .from("orders")
        .insert(orders)
        .select();

      if (orderError) {
        console.error("Error creating orders:", orderError);
        throw orderError;
      }

      console.log("Orders created successfully:", insertedOrders);

      // Update request statuses
      const { data: updatedRequests, error: requestError } = await supabase
        .from("requests")
        .update({
          status: "scheduled",
          approved_by: user.id,
          approved_at: new Date().toISOString(),
        })
        .in("id", selectedRequestIds)
        .select();

      if (requestError) {
        console.error("Error updating requests:", requestError);
        throw requestError;
      }

      console.log("Requests updated successfully:", updatedRequests);

      // Close dialog and refresh data
      setDialogOpen(false);
      await fetchRequests();

      // Reset form
      setPickupTime("");
      setDropoffTime("");
      setDriver("");
      setSelectedRequest(null);

      alert("Orders scheduled successfully!");
    } catch (error) {
      console.error("Error scheduling orders:", error);
      alert("Failed to schedule orders: " + error.message);
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        {groupBy === "buyer"
          ? "Buyer Requests Dashboard"
          : "Donor Requests Dashboard"}
      </Typography>

      <Box sx={{ mb: 2 }}>
        <Button
          variant={groupBy === "buyer" ? "contained" : "outlined"}
          onClick={() => setGroupBy("buyer")}
          sx={{ mr: 1 }}>
          Group by Buyer
        </Button>
        <Button
          variant={groupBy === "donor" ? "contained" : "outlined"}
          onClick={() => setGroupBy("donor")}>
          Group by Donor
        </Button>
      </Box>

      <RequestTable
        groupedRequests={groupBy === "buyer" ? groupedByBuyer : groupedRequests}
        onSchedule={(request) => {
          setSelectedRequest(request);
          setDialogOpen(true);
        }}
        groupBy={groupBy}
      />

      <ScheduleDialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
          setSelectedRequest(null);
          setPickupTime("");
          setDropoffTime("");
          setDriver("");
        }}
        selectedRequest={selectedRequest}
        groupedRequests={groupBy === "buyer" ? groupedByBuyer : groupedRequests}
        groupBy={groupBy}
        drivers={drivers}
        pickupTime={pickupTime}
        setPickupTime={setPickupTime}
        dropoffTime={dropoffTime}
        setDropoffTime={setDropoffTime}
        driver={driver}
        setDriver={setDriver}
        onSave={handleSchedule}
      />
    </Box>
  );
};

export default AdminDashboard;

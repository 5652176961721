import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Button,
} from "@mui/material";
import { supabase } from "../../supabaseClient";

export function FeaturedItemsSection() {
  const [items, setItems] = useState([]);

  // Fetch items from Supabase
  const fetchItems = async () => {
    try {
      const { data, error } = await supabase
        .from("items")
        .select("*")
        .order("created_at", { ascending: false }) // Sort by most recent
        .limit(2); // Fetch only the top 2 items
      if (error) throw error;

      setItems(data);
    } catch (error) {
      console.error("Error fetching featured items:", error);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <Box sx={{ py: 6, backgroundColor: "#f9f9f9" }}>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{ fontWeight: "bold", mb: 4 }}>
        Featured Items
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {items.map((item, index) => {
          let imageUrl = "/images/placeholder.jpg"; // Default image
          try {
            // Parse the JSON string and extract the first URL
            const parsedUrls = JSON.parse(item.image_url);
            if (Array.isArray(parsedUrls) && parsedUrls.length > 0) {
              imageUrl = parsedUrls[0];
            }
          } catch (error) {
            console.error("Error parsing image_url:", error);
          }

          return (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  transition: "transform 0.3s, box-shadow 0.3s",
                  "&:hover": { transform: "scale(1.05)", boxShadow: 6 },
                }}>
                <CardMedia
                  component="img"
                  height="200"
                  image={imageUrl}
                  alt={item.title}
                />
                <CardContent>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    {item.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2 }}>
                    {item.description}
                  </Typography>
                  <Button variant="contained" color="primary" fullWidth>
                    View Item
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      <Box textAlign="center" sx={{ mt: 4 }}>
        <Button variant="outlined" color="primary" size="large">
          View All Items
        </Button>
      </Box>
    </Box>
  );
}

import React, { useState } from "react";
import { supabase } from "../../supabaseClient";
import {
  TextField,
  Button,
  Box,
  Typography,
  MenuItem,
  Grid,
  Snackbar,
  Alert,
  Card,
  CardMedia,
  IconButton,
} from "@mui/material";
import { Upload } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";

const AddItem = () => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    category: "",
    size: "",
    stock: 1,
    condition: "",
    location: "",
    status: "available",
  });
  const [imageFiles, setImageFiles] = useState([]); // Store selected files
  const [previewUrls, setPreviewUrls] = useState([]); // Store preview URLs
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImageFiles((prev) => [...prev, ...files]);

    // Generate preview URLs
    const urls = files.map((file) => URL.createObjectURL(file));
    setPreviewUrls((prev) => [...prev, ...urls]);
  };

  const removeImage = (index) => {
    const updatedFiles = [...imageFiles];
    const updatedPreviews = [...previewUrls];
    updatedFiles.splice(index, 1);
    updatedPreviews.splice(index, 1);
    setImageFiles(updatedFiles);
    setPreviewUrls(updatedPreviews);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      const imageUrls = [];

      // Upload each image and get its public URL
      for (const imageFile of imageFiles) {
        const { data: uploadData, error: uploadError } = await supabase.storage
          .from("item-images")
          .upload(`public/${Date.now()}-${imageFile.name}`, imageFile, {
            cacheControl: "3600",
            upsert: false,
          });

        if (uploadError) throw uploadError;

        const { data: publicUrl } = supabase.storage
          .from("item-images")
          .getPublicUrl(uploadData.path);

        imageUrls.push(publicUrl.publicUrl);
      }

      // Fetch the current user's ID
      const { data: sessionData, error: sessionError } =
        await supabase.auth.getSession();

      if (sessionError) {
        throw sessionError;
      }

      const donorId = sessionData.session?.user?.id;

      if (!donorId) {
        setError("User is not authenticated.");
        return;
      }

      // Validate stock
      const stock = parseInt(formData.stock);
      if (isNaN(stock) || stock < 0) {
        setError("Stock must be a valid number greater than or equal to 0");
        return;
      }

      // Insert item with status based on stock
      const { error: insertError } = await supabase.from("items").insert([
        {
          ...formData,
          stock: stock,
          status: stock > 0 ? 'available' : 'out_of_stock',
          image_url: JSON.stringify(imageUrls),
          donor_id: donorId,
        },
      ]);

      if (insertError) throw insertError;

      setSuccess(true);
      setFormData({
        title: "",
        description: "",
        category: "",
        size: "",
        stock: 1,
        condition: "",
        location: "",
        status: "available",
      });
      setImageFiles([]);
      setPreviewUrls([]);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 800,
        mx: "auto",
        mt: 6,
        p: 4,
        backgroundColor: "background.paper",
        borderRadius: 3,
        boxShadow: 3,
      }}>
      <Typography variant="h4" align="center" fontWeight="bold" gutterBottom>
        Add New Item
      </Typography>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label="Title"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              required
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              multiline
              rows={4}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Category"
              name="category"
              value={formData.category}
              onChange={handleInputChange}
              select
              fullWidth
              required
              variant="outlined">
              <MenuItem value="clothing">Clothing</MenuItem>
              <MenuItem value="furniture">Furniture</MenuItem>
              <MenuItem value="electronics">Electronics</MenuItem>
              <MenuItem value="others">Others</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Size"
              name="size"
              value={formData.size}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Condition"
              name="condition"
              value={formData.condition}
              onChange={handleInputChange}
              select
              fullWidth
              required
              variant="outlined">
              <MenuItem value="new">New</MenuItem>
              <MenuItem value="used-good">Used - Good</MenuItem>
              <MenuItem value="used-fair">Used - Fair</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Stock"
              name="stock"
              value={formData.stock}
              onChange={handleInputChange}
              type="number"
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="outlined"
              component="label"
              startIcon={<Upload />}
              fullWidth
              sx={{ borderRadius: 2 }}>
              Upload Images
              <input
                type="file"
                hidden
                accept="image/*"
                multiple
                onChange={handleImageChange}
              />
            </Button>
          </Grid>

          {/* Image Previews */}
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {previewUrls.map((url, index) => (
                <Grid item xs={6} sm={4} md={3} key={index}>
                  <Card sx={{ position: "relative" }}>
                    <CardMedia
                      component="img"
                      image={url}
                      alt={`Preview ${index + 1}`}
                      sx={{ height: 140, objectFit: "cover" }}
                    />
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                        backgroundColor: "rgba(255,255,255,0.7)",
                      }}
                      onClick={() => removeImage(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              sx={{ borderRadius: 2, py: 1.5 }}>
              Submit Item
            </Button>
          </Grid>
        </Grid>
      </form>

      {/* Snackbar Notifications */}
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}>
        <Alert severity="success" sx={{ width: "100%" }}>
          Item added successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError("")}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddItem;

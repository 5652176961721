import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { supabase } from "../../supabaseClient";
import { AuthContext } from "../../context/AuthContext";
import {
  Box,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Button,
  Snackbar,
  Alert,
  Grid,
  TextField,
} from "@mui/material";

// const ItemDetails = ({ addToCart }) => {
//   const { user } = useContext(AuthContext);
//   const { id } = useParams();
//   const [item, setItem] = useState(null);
//   const [error, setError] = useState("");
//   const [success, setSuccess] = useState(false);
//   const [selectedImage, setSelectedImage] = useState("");
//   const navigate = useNavigate();
//   const [quantity, setQuantity] = useState(1);

//   const fetchItemDetails = async () => {
//     try {
//       const { data, error } = await supabase
//         .from("items")
//         .select("*")
//         .eq("id", id)
//         .single();

//       if (error) throw error;
//       setItem(data);

//       // Set the first image as the default selected image
//       const images = parseImageUrls(data.image_url);
//       if (images.length > 0) {
//         setSelectedImage(images[0]);
//       }
//     } catch (err) {
//       setError(err.message);
//     }
//   };

//   useEffect(() => {
//     fetchItemDetails();
//   }, [id]);

//   const parseImageUrls = (imageUrl) => {
//     try {
//       return JSON.parse(imageUrl) || [];
//     } catch {
//       return ["/placeholder.jpg"]; // Fallback
//     }
//   };

//   const handleRequest = async (quantity = 1) => {
//     try {
//       if (!user) {
//         alert("Please log in to request items");
//         navigate("/login");
//         return;
//       }

//       // Ensure quantity is a number
//       const requestQuantity = typeof quantity === 'object' ? 1 : parseInt(quantity);

//       console.log('Current item:', item); // Debug log

//       // First check if there's enough stock
//       const { data: currentItem, error: itemError } = await supabase
//         .from('items')
//         .select('*')  // Select all fields to ensure we have everything we need
//         .eq('id', id)  // Use the id from useParams()
//         .single();

//       if (itemError) {
//         console.error('Error fetching item:', itemError);
//         throw itemError;
//       }

//       console.log('Current item from DB:', currentItem); // Debug log

//       if (!currentItem.stock || currentItem.stock < requestQuantity) {
//         alert("Sorry, not enough items in stock");
//         return;
//       }

//       if (!currentItem.donor_id) {
//         console.error('No donor_id found for item:', currentItem);
//         throw new Error('Item has no donor_id');
//       }

//       // Call addToCart with all required fields
//       const cartData = {
//         item_id: currentItem.id,
//         buyer_id: user.id,
//         donor_id: currentItem.donor_id,
//         quantity: requestQuantity  // Use the parsed quantity
//       };

//       console.log('Cart data being sent:', cartData); // Debug log

//       const success = await addToCart(cartData);

//       if (success) {
//         // Update the item's stock
//         const newStock = currentItem.stock - requestQuantity;
//         const { error: updateError } = await supabase
//           .from('items')
//           .update({
//             stock: newStock,
//             status: newStock === 0 ? 'out_of_stock' : 'available'
//           })
//           .eq('id', currentItem.id);

//         if (updateError) {
//           console.error('Error updating stock:', updateError);
//           throw updateError;
//         }

//         alert("Item added to cart successfully!");
//       }
//     } catch (error) {
//       console.error('Error in handleRequest:', error);
//       alert('Failed to add item to cart: ' + error.message);
//     }
//   };

//   if (!item) {
//     return <Typography>Loading item details...</Typography>;
//   }

//   const images = parseImageUrls(item.image_url);

//   return (
//     <Box sx={{ maxWidth: 800, mx: "auto", mt: 4, p: 2 }}>
//       <Card>
//         {/* Main Image */}
//         <CardMedia
//           component="img"
//           height="400"
//           image={selectedImage}
//           alt={item.title}
//           sx={{ objectFit: "cover" }}
//         />
//         <CardContent>
//           <Typography variant="h4" fontWeight="bold">
//             {item.title}
//           </Typography>
//           <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
//             {item.description}
//           </Typography>
//           <Typography variant="body2" sx={{ mt: 2 }}>
//             <strong>Category:</strong> {item.category}
//           </Typography>
//           <Typography variant="body2">
//             <strong>Condition:</strong> {item.condition}
//           </Typography>
//           <Typography variant="body2">
//             <strong>Stock:</strong> {item.stock}
//           </Typography>

//           {/* Stock status indicator */}
//           <Typography
//             variant="body2"
//             color={item.stock > 0 ? "success.main" : "error.main"}
//             sx={{ mt: 1 }}
//           >
//             {item.stock > 0
//               ? `${item.stock} items available`
//               : "Currently out of stock"}
//           </Typography>

//           {/* Add quantity selector */}
//           <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, my: 2 }}>
//             <Typography>Quantity:</Typography>
//             <TextField
//               type="number"
//               value={quantity}
//               onChange={(e) => {
//                 const value = parseInt(e.target.value);
//                 if (value > 0 && value <= item.stock) {
//                   setQuantity(value);
//                 }
//               }}
//               InputProps={{
//                 inputProps: {
//                   min: 1,
//                   max: item.stock
//                 }
//               }}
//               size="small"
//               sx={{ width: 100 }}
//             />
//             <Typography variant="body2" color="text.secondary">
//               (Max: {item.stock} available)
//             </Typography>
//           </Box>

//           {/* Update Add to Cart button */}
//           {item.stock > 0 ? (
//             <Button
//               variant="contained"
//               color="primary"
//               sx={{ mt: 2 }}
//               onClick={() => handleRequest(quantity)}
//               disabled={quantity < 1 || quantity > item.stock}
//             >
//               Add to Cart
//             </Button>
//           ) : (
//             <Button
//               variant="contained"
//               disabled
//               sx={{ mt: 2 }}
//             >
//               Out of Stock
//             </Button>
//           )}
//         </CardContent>

//         {/* Thumbnails */}
//         <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
//           <Grid container spacing={1} justifyContent="center">
//             {images.map((url, index) => (
//               <Grid item xs={3} key={index}>
//                 <CardMedia
//                   component="img"
//                   height="80"
//                   image={url}
//                   alt={`Thumbnail ${index + 1}`}
//                   sx={{
//                     objectFit: "cover",
//                     cursor: "pointer",
//                     border:
//                       selectedImage === url ? "2px solid #1976d2" : "none",
//                   }}
//                   onClick={() => setSelectedImage(url)}
//                 />
//               </Grid>
//             ))}
//           </Grid>
//         </Box>
//       </Card>

//       {/* Success Snackbar */}
//       <Snackbar
//         open={success}
//         autoHideDuration={6000}
//         onClose={() => setSuccess(false)}>
//         <Alert severity="success" sx={{ width: "100%" }}>
//           Item added to cart!
//         </Alert>
//       </Snackbar>

//       {/* Error Snackbar */}
//       <Snackbar
//         open={!!error}
//         autoHideDuration={6000}
//         onClose={() => setError("")}>
//         <Alert severity="error" sx={{ width: "100%" }}>
//           {error}
//         </Alert>
//       </Snackbar>
//     </Box>
//   );
// };

// export default ItemDetails;

const ItemDetails = ({ addToCart }) => {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState(1);

  const fetchItemDetails = async () => {
    try {
      const { data, error } = await supabase
        .from("items")
        .select("*")
        .eq("id", id)
        .single();

      if (error) throw error;
      setItem(data);

      const images = parseImageUrls(data.image_url);
      if (images.length > 0) {
        setSelectedImage(images[0]);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const parseImageUrls = (imageUrl) => {
    try {
      const urls = JSON.parse(imageUrl);
      return Array.isArray(urls) ? urls : ["/placeholder.jpg"];
    } catch {
      return ["/placeholder.jpg"];
    }
  };

  useEffect(() => {
    fetchItemDetails();
  }, [id]);

  const handleAddToCart = () => {
    if (!user) {
      alert("Please log in to add items to cart");
      navigate("/login");
      return;
    }

    if (!item || item.stock < quantity) {
      alert("Not enough stock.");
      return;
    }

    addToCart({
      item_id: item.id,
      donor_id: item.donor_id,
      title: item.title,
      description: item.description,
      category: item.category,
      condition: item.condition,
      image_url: item.image_url,
      quantity: quantity,
      stock: item.stock,
    });

    setSuccess(true);
  };

  if (!item) {
    return <Typography>Loading item details...</Typography>;
  }

  const images = parseImageUrls(item.image_url);

  return (
    <Box sx={{ maxWidth: 800, mx: "auto", mt: 4, p: 2 }}>
      <Card>
        <CardMedia
          component="img"
          height="400"
          image={selectedImage}
          alt={item.title}
          sx={{ objectFit: "cover" }}
        />
        <CardContent>
          <Typography variant="h4" fontWeight="bold">
            {item.title}
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
            {item.description}
          </Typography>
          <Typography variant="body2" sx={{ mt: 2 }}>
            <strong>Category:</strong> {item.category}
          </Typography>
          <Typography variant="body2">
            <strong>Condition:</strong> {item.condition}
          </Typography>
          <Typography variant="body2">
            <strong>Stock:</strong> {item.stock}
          </Typography>

          <Typography
            variant="body2"
            color={item.stock > 0 ? "success.main" : "error.main"}
            sx={{ mt: 1 }}>
            {item.stock > 0
              ? `${item.stock} items available`
              : "Currently out of stock"}
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center", gap: 2, my: 2 }}>
            <Typography>Quantity:</Typography>
            <TextField
              type="number"
              value={quantity}
              onChange={(e) => {
                const val = parseInt(e.target.value, 10);
                if (val > 0 && val <= item.stock) {
                  setQuantity(val);
                }
              }}
              InputProps={{ inputProps: { min: 1, max: item.stock } }}
              size="small"
              sx={{ width: 100 }}
            />
            <Typography variant="body2" color="text.secondary">
              (Max: {item.stock} available)
            </Typography>
          </Box>

          {item.stock > 0 ? (
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              onClick={handleAddToCart}
              disabled={quantity < 1 || quantity > item.stock}>
              Add to Cart
            </Button>
          ) : (
            <Button variant="contained" disabled sx={{ mt: 2 }}>
              Out of Stock
            </Button>
          )}
        </CardContent>

        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Grid container spacing={1} justifyContent="center">
            {images.map((url, index) => (
              <Grid item xs={3} key={index}>
                <CardMedia
                  component="img"
                  height="80"
                  image={url}
                  alt={`Thumbnail ${index + 1}`}
                  sx={{
                    objectFit: "cover",
                    cursor: "pointer",
                    border:
                      selectedImage === url ? "2px solid #1976d2" : "none",
                  }}
                  onClick={() => setSelectedImage(url)}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Card>

      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}>
        <Alert severity="success" sx={{ width: "100%" }}>
          Item added to cart!
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError("")}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ItemDetails;

import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  FormControlLabel,
  Box,
} from "@mui/material";

const ScheduleDialog = ({
  open,
  onClose,
  selectedRequest,
  groupedRequests,
  groupBy,
  drivers,
  pickupTime,
  setPickupTime,
  dropoffTime,
  setDropoffTime,
  driver,
  setDriver,
  onSave,
}) => {
  const [selectedRequests, setSelectedRequests] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  // Get all related requests based on groupBy
  const getRelatedRequests = () => {
    if (!selectedRequest) return [];
    
    if (groupBy === "buyer") {
      // Get all requests from the same cart group
      const buyerData = groupedRequests[selectedRequest.buyer_id];
      const cartGroup = buyerData?.cartGroups[selectedRequest.cart_id];
      return cartGroup?.requests || [];
    } else {
      // Get all requests from the same donor
      const donorData = groupedRequests[selectedRequest.donor_id];
      return donorData?.requests || [];
    }
  };

  const relatedRequests = getRelatedRequests();

  const handleSelectAll = (event) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      setSelectedRequests(relatedRequests.map(req => req.id));
    } else {
      setSelectedRequests([]);
    }
  };

  const handleSelectRequest = (requestId) => {
    setSelectedRequests(prev => {
      if (prev.includes(requestId)) {
        return prev.filter(id => id !== requestId);
      } else {
        return [...prev, requestId];
      }
    });
  };

  const handleSave = () => {
    onSave(selectedRequests);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Schedule Orders</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6" gutterBottom>
            {groupBy === "buyer" ? "Orders from same cart" : "Orders from same donor"}
          </Typography>
          
          <FormControlLabel
            control={
              <Checkbox
                checked={selectAll}
                onChange={handleSelectAll}
              />
            }
            label="Select All"
          />

          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox checked={selectAll} onChange={handleSelectAll} />
                </TableCell>
                <TableCell>Item</TableCell>
                <TableCell>{groupBy === "buyer" ? "Donor" : "Buyer"}</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {relatedRequests.map((request) => (
                <TableRow key={request.id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedRequests.includes(request.id)}
                      onChange={() => handleSelectRequest(request.id)}
                    />
                  </TableCell>
                  <TableCell>{request.items.title}</TableCell>
                  <TableCell>
                    {groupBy === "buyer" 
                      ? `${request.donor.first_name} ${request.donor.last_name}`
                      : `${request.buyer.first_name} ${request.buyer.last_name}`
                    }
                  </TableCell>
                  <TableCell>{request.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>

        <TextField
          label="Pickup Time"
          type="datetime-local"
          fullWidth
          value={pickupTime}
          onChange={(e) => setPickupTime(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          label="Dropoff Time"
          type="datetime-local"
          fullWidth
          value={dropoffTime}
          onChange={(e) => setDropoffTime(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Select
          fullWidth
          value={driver}
          onChange={(e) => setDriver(e.target.value)}
          displayEmpty
          sx={{ mb: 2 }}>
          <MenuItem value="" disabled>
            Assign Driver
          </MenuItem>
          {drivers.map((d) => (
            <MenuItem key={d.id} value={d.id}>
              {d.first_name} {d.last_name} ({d.vehicle_type})
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={handleSave} 
          variant="contained" 
          color="success"
          disabled={selectedRequests.length === 0}
        >
          Schedule Selected ({selectedRequests.length})
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScheduleDialog;

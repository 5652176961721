import React, { useEffect, useState } from "react";
import { supabase } from "../../supabaseClient";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const HistoryPage = () => {
  const [history, setHistory] = useState([]);

  useEffect(() => {
    fetchHistory();
  }, []);

  const fetchHistory = async () => {
    try {
      const { data, error } = await supabase.from("requests").select(`
        *,
        buyer:buyer_id (
          first_name,
          last_name,
          profiles (address)
        ),
        donor:donor_id (
          first_name,
          last_name,
          profiles (address)
        ),
        items (
          title,
          category
        ),
        orders (
          pickup_time,
          dropoff_time,
          status
        )
      `);

      if (error) throw error;

      // Format data if necessary (optional)
      const formattedData = data.map((entry) => ({
        ...entry,
        buyer_address: entry.buyer?.profiles?.address || "Unknown location",
        donor_address: entry.donor?.profiles?.address || "Unknown location",
        item_title: entry.items?.title || "Unknown item",
        item_category: entry.items?.category || "Unknown category",
        order_status: entry.orders?.status || entry.status,
        pickup_time: entry.orders?.pickup_time || "Not scheduled",
        dropoff_time: entry.orders?.dropoff_time || "Not scheduled",
      }));

      setHistory(formattedData);
    } catch (err) {
      console.error("Error fetching history:", err.message);
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Requests and Orders History
      </Typography>
      {history.length === 0 ? (
        <Typography>No history available.</Typography>
      ) : (
        <Paper sx={{ p: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell>Buyer</TableCell>
                <TableCell>Donor</TableCell>
                <TableCell>Pickup Location</TableCell>
                <TableCell>Dropoff Location</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Pickup Time</TableCell>
                <TableCell>Dropoff Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history.map((entry) => (
                <TableRow key={entry.id}>
                  <TableCell>
                    {entry.item_title} - {entry.item_category}
                  </TableCell>
                  <TableCell>
                    {entry.buyer?.first_name} {entry.buyer?.last_name}
                  </TableCell>
                  <TableCell>
                    {entry.donor?.first_name} {entry.donor?.last_name}
                  </TableCell>
                  <TableCell>{entry.buyer_address}</TableCell>
                  <TableCell>{entry.donor_address}</TableCell>
                  <TableCell>{entry.order_status}</TableCell>
                  <TableCell>{entry.pickup_time}</TableCell>
                  <TableCell>{entry.dropoff_time}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}
    </Box>
  );
};

export default HistoryPage;

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Select,
  MenuItem,
  Paper,
} from "@mui/material";
import { supabase } from "../../supabaseClient";

const AdminUserManagement = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [roleFilter, setRoleFilter] = useState("");

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const { data, error } = await supabase.from("users").select(`
        id,
        first_name,
        last_name,
        email,
        phone,
        role,
        profiles (address),
        user_availability (available_days, available_times)
      `);

      if (error) throw error;

      setUsers(data);
      setFilteredUsers(data);
    } catch (err) {
      console.error("Error fetching users:", err.message);
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = users.filter(
      (user) =>
        user.first_name.toLowerCase().includes(query) ||
        user.last_name.toLowerCase().includes(query) ||
        user.email.toLowerCase().includes(query)
    );

    if (roleFilter) {
      setFilteredUsers(filtered.filter((user) => user.role === roleFilter));
    } else {
      setFilteredUsers(filtered);
    }
  };

  const handleFilterChange = (e) => {
    const filter = e.target.value;
    setRoleFilter(filter);

    if (filter) {
      setFilteredUsers(
        users.filter(
          (user) =>
            (user.first_name.toLowerCase().includes(searchQuery) ||
              user.last_name.toLowerCase().includes(searchQuery) ||
              user.email.toLowerCase().includes(searchQuery)) &&
            user.role === filter
        )
      );
    } else {
      setFilteredUsers(
        users.filter(
          (user) =>
            user.first_name.toLowerCase().includes(searchQuery) ||
            user.last_name.toLowerCase().includes(searchQuery) ||
            user.email.toLowerCase().includes(searchQuery)
        )
      );
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        User Management
      </Typography>
      <Paper sx={{ p: 3, mb: 4 }}>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}>
          <TextField
            label="Search Users"
            variant="outlined"
            value={searchQuery}
            onChange={handleSearch}
            fullWidth
            sx={{ flex: 1, minWidth: "300px" }}
          />
          <Select
            value={roleFilter}
            onChange={handleFilterChange}
            displayEmpty
            fullWidth
            sx={{ flex: 1, minWidth: "200px" }}>
            <MenuItem value="">All Roles</MenuItem>
            <MenuItem value="donor">Donors</MenuItem>
            <MenuItem value="buyer">Buyers</MenuItem>
          </Select>
        </Box>
      </Paper>

      <Paper sx={{ p: 3 }}>
        {filteredUsers.length === 0 ? (
          <Typography>No users found.</Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Availability</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>
                    {user.first_name} {user.last_name}
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.phone || "N/A"}</TableCell>
                  <TableCell>{user.role}</TableCell>
                  <TableCell>
                    {user.profiles?.address || "No address provided"}
                  </TableCell>
                  <TableCell>
                    {user.user_availability
                      ? `${
                          user.user_availability.available_days?.join(", ") ||
                          "No days"
                        } 
            (${
              user.user_availability.available_times?.join(", ") || "No times"
            })`
                      : "No availability"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Paper>
    </Box>
  );
};

export default AdminUserManagement;

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { supabase } from "../../supabaseClient";
import DeleteIcon from "@mui/icons-material/Delete";

const AdminDriverManagement = () => {
  const [drivers, setDrivers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [newDriver, setNewDriver] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    vehicle_type: "car",
    available_days: [],
    available_times: [],
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [availableDays, setAvailableDays] = useState([
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ]);

  useEffect(() => {
    fetchDrivers();
  }, []);

  const fetchDrivers = async () => {
    try {
      const { data, error } = await supabase.from("drivers").select("*");
      if (error) throw error;
      setDrivers(data);
    } catch (err) {
      console.error("Error fetching drivers:", err.message);
    }
  };

  const handleAddDriver = async () => {
    try {
      const { error } = await supabase.from("drivers").insert([newDriver]);
      if (error) throw error;
      setSuccessMessage("Driver added successfully!");
      setNewDriver({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        vehicle_type: "car",
        available_days: [],
        available_times: [],
      });
      fetchDrivers();
      setOpenDialog(false);
    } catch (err) {
      setErrorMessage("Error adding driver: " + err.message);
    }
  };

  const handleDeleteDriver = async (id) => {
    try {
      const { error } = await supabase.from("drivers").delete().eq("id", id);
      if (error) throw error;
      setSuccessMessage("Driver removed successfully!");
      fetchDrivers();
    } catch (err) {
      setErrorMessage("Error removing driver: " + err.message);
    }
  };

  const handleEditAvailability = async () => {
    try {
      const { error } = await supabase
        .from("drivers")
        .update({
          available_days: selectedDriver.available_days,
          available_times: selectedDriver.available_times,
        })
        .eq("id", selectedDriver.id);

      if (error) throw error;

      setSuccessMessage("Driver availability updated successfully!");
      fetchDrivers();
      setOpenEditDialog(false);
    } catch (err) {
      setErrorMessage("Error updating availability: " + err.message);
    }
  };

  const toggleDay = (day) => {
    setSelectedDriver((prev) => ({
      ...prev,
      available_days: prev.available_days.includes(day)
        ? prev.available_days.filter((d) => d !== day)
        : [...prev.available_days, day],
    }));
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Driver Management
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpenDialog(true)}
        sx={{ mb: 3 }}>
        Add Driver
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Vehicle Type</TableCell>
            <TableCell>Availability</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {drivers.map((driver) => (
            <TableRow key={driver.id}>
              <TableCell>
                {driver.first_name} {driver.last_name}
              </TableCell>
              <TableCell>{driver.email}</TableCell>
              <TableCell>{driver.phone || "N/A"}</TableCell>
              <TableCell>{driver.vehicle_type}</TableCell>
              <TableCell>
                Days: {driver.available_days?.join(", ") || "No days"} <br />
                Times: {driver.available_times?.join(", ") || "No times"}
              </TableCell>
              <TableCell>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setSelectedDriver(driver);
                    setOpenEditDialog(true);
                  }}
                  sx={{ mr: 1 }}>
                  Edit Availability
                </Button>
                <IconButton
                  color="error"
                  onClick={() => handleDeleteDriver(driver.id)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Add Driver Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Add New Driver</DialogTitle>
        <DialogContent>
          <TextField
            label="First Name"
            fullWidth
            sx={{ mb: 2 }}
            value={newDriver.first_name}
            onChange={(e) =>
              setNewDriver((prev) => ({ ...prev, first_name: e.target.value }))
            }
          />
          <TextField
            label="Last Name"
            fullWidth
            sx={{ mb: 2 }}
            value={newDriver.last_name}
            onChange={(e) =>
              setNewDriver((prev) => ({ ...prev, last_name: e.target.value }))
            }
          />
          <TextField
            label="Email"
            fullWidth
            sx={{ mb: 2 }}
            value={newDriver.email}
            onChange={(e) =>
              setNewDriver((prev) => ({ ...prev, email: e.target.value }))
            }
          />
          <TextField
            label="Phone"
            fullWidth
            sx={{ mb: 2 }}
            value={newDriver.phone}
            onChange={(e) =>
              setNewDriver((prev) => ({ ...prev, phone: e.target.value }))
            }
          />
          <Select
            fullWidth
            value={newDriver.vehicle_type}
            onChange={(e) =>
              setNewDriver((prev) => ({
                ...prev,
                vehicle_type: e.target.value,
              }))
            }
            sx={{ mb: 2 }}>
            <MenuItem value="car">Car</MenuItem>
            <MenuItem value="truck">Truck</MenuItem>
          </Select>
          <Typography sx={{ mt: 2 }}>Select Available Days:</Typography>
          {availableDays.map((day) => (
            <FormControlLabel
              key={day}
              control={
                <Checkbox
                  checked={newDriver.available_days.includes(day)}
                  onChange={() =>
                    setNewDriver((prev) => ({
                      ...prev,
                      available_days: prev.available_days.includes(day)
                        ? prev.available_days.filter((d) => d !== day)
                        : [...prev.available_days, day],
                    }))
                  }
                />
              }
              label={day}
            />
          ))}
          <TextField
            label="Available Times (e.g., 09:00-12:00, 14:00-17:00)"
            fullWidth
            sx={{ mt: 2 }}
            value={newDriver.available_times.join(", ")}
            onChange={(e) =>
              setNewDriver((prev) => ({
                ...prev,
                available_times: e.target.value.split(",").map((t) => t.trim()),
              }))
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleAddDriver} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Availability Dialog */}
      <Dialog
        open={openEditDialog}
        onClose={() => {
          setOpenEditDialog(false);
          setSelectedDriver(null);
        }}>
        <DialogTitle>Edit Driver Availability</DialogTitle>
        <DialogContent>
          <Typography>Select Available Days:</Typography>
          {availableDays.map((day) => (
            <FormControlLabel
              key={day}
              control={
                <Checkbox
                  checked={selectedDriver?.available_days.includes(day)}
                  onChange={() => toggleDay(day)}
                />
              }
              label={day}
            />
          ))}
          <TextField
            label="Available Times (e.g., 09:00-12:00, 14:00-17:00)"
            fullWidth
            sx={{ mt: 2 }}
            value={selectedDriver?.available_times.join(", ")}
            onChange={(e) =>
              setSelectedDriver((prev) => ({
                ...prev,
                available_times: e.target.value.split(",").map((t) => t.trim()),
              }))
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenEditDialog(false);
              setSelectedDriver(null);
            }}>
            Cancel
          </Button>
          <Button onClick={handleEditAvailability} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar Notifications */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage("")}>
        <Alert severity="success" sx={{ width: "100%" }}>
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage("")}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AdminDriverManagement;

import { Box, Button, Typography, Container } from "@mui/material";
import heroImage from "../../resources/hero.jpg";

export function HeroSection() {
  return (
    <Box
      sx={{
        py: 25,
        backgroundImage: `url(${heroImage})`,
        backgroundSize: "cover", // Ensure the image covers the entire container
        backgroundRepeat: "no-repeat", // Prevent tiling
        backgroundPosition: "center ", // Center the image
        textAlign: "center",
        color: "white",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          inset: 0,
          background: "rgba(0, 0, 0, 0.5)", // Dark overlay for better contrast
        }}
      />
      <Container maxWidth="lg" sx={{ position: "relative", zIndex: 1 }}>
        <Typography
          variant="h2"
          component="h1"
          gutterBottom
          sx={{ fontWeight: "bold" }}
        >
          Give a New Life to Your Unused Items
        </Typography>
        <Typography variant="h6" component="p" sx={{ mb: 4 }}>
          Connect with families in need by donating clothes, furniture, and
          more.
        </Typography>
        <Box>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ mr: 2, textTransform: "none", px: 4, py: 1 }}
          >
            Browse Items
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

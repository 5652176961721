import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Box,
} from "@mui/material";

const RequestTable = ({ groupedRequests, onSchedule, groupBy }) => {
  const renderGroupedRequests = () => {
    return Object.entries(groupedRequests).map(([userId, data]) => {
      const userInfo = groupBy === "donor" ? data.donorInfo : data.buyerInfo;

      return (
        <React.Fragment key={userId}>
          <TableRow>
            <TableCell colSpan={6}>
              <Typography variant="h6">
                {userInfo?.first_name} {userInfo?.last_name} - {userInfo?.email}
              </Typography>
              <Typography variant="body2">
                Address: {userInfo?.profiles?.address}
              </Typography>
            </TableCell>
          </TableRow>

          {/* If viewing by buyer, show cart groups */}
          {groupBy === "buyer" &&
            Object.entries(data.cartGroups).map(([cartId, cartGroup]) => (
              <React.Fragment key={cartId}>
                <TableRow>
                  <TableCell colSpan={6}>
                    <Typography variant="subtitle2" sx={{ pl: 2 }}>
                      Order Time:{" "}
                      {new Date(cartGroup.timestamp).toLocaleString()}
                    </Typography>
                  </TableCell>
                </TableRow>
                {cartGroup.requests.map((request) => (
                  <TableRow key={request.id}>
                    <TableCell sx={{ pl: 4 }}>{request.items.title}</TableCell>
                    <TableCell>{request.items.category}</TableCell>
                    <TableCell>{request.items.condition}</TableCell>
                    <TableCell>
                      {request.donor.first_name} {request.donor.last_name}
                    </TableCell>
                    <TableCell>{request.status}</TableCell>
                    <TableCell>{request.quantity}</TableCell>

                    <TableCell>
                      {request.status === "pending" && (
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => onSchedule(request)}>
                          Schedule
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}

          {/* If viewing by donor, show requests directly */}
          {groupBy === "donor" &&
            data.requests?.map((request) => (
              <TableRow key={request.id}>
                <TableCell>{request.items.title}</TableCell>
                <TableCell>{request.items.category}</TableCell>
                <TableCell>{request.items.condition}</TableCell>
                <TableCell>
                  {request.buyer.first_name} {request.buyer.last_name}
                </TableCell>
                <TableCell>{request.status}</TableCell>
                <TableCell>{request.quantity}</TableCell>
                <TableCell>
                  {request.status === "pending" && (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => onSchedule(request)}>
                      Schedule
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </React.Fragment>
      );
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Item</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Condition</TableCell>
            <TableCell>{groupBy === "donor" ? "Buyer" : "Donor"}</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(groupedRequests).length === 0 ? (
            <TableRow>
              <TableCell colSpan={6} align="center">
                No requests found
              </TableCell>
            </TableRow>
          ) : (
            renderGroupedRequests()
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RequestTable;

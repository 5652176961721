import React from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  Stack,
} from "@mui/material";
import { Email, Phone, LocationOn } from "@mui/icons-material";

const ContactUs = () => {
  return (
    <>
      {/* Hero Section */}
      <Box
        sx={{
          bgcolor: "primary.main",
          color: "white",
          width: "100%",
          py: 10,
          px: 2,
          textAlign: "center",
        }}>
        <Typography variant="h3" fontWeight="bold">
          Contact Us
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ mt: 2, maxWidth: 800, mx: "auto", px: 2 }}>
          Have questions? We’re here to help. Fill out the form below, or use
          the contact information provided to get in touch.
        </Typography>
      </Box>

      {/* Main Content */}
      <Box
        sx={{
          maxWidth: 1200,
          mx: "auto",
          mt: 8,
          px: 3,
        }}>
        <Grid container spacing={6}>
          {/* Contact Form */}
          <Grid item xs={12} md={7}>
            <Paper
              elevation={4}
              sx={{
                p: 4,
                borderRadius: 2,
                bgcolor: "background.paper",
              }}>
              <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
                Send Us a Message
              </Typography>
              <Typography
                variant="body1"
                sx={{ mb: 4, color: "text.secondary" }}>
                We’d love to hear from you. Fill out the form below, and we’ll
                get back to you shortly.
              </Typography>
              <form>
                <Stack spacing={3}>
                  <TextField
                    label="Name"
                    variant="outlined"
                    fullWidth
                    required
                  />
                  <TextField
                    label="Email"
                    type="email"
                    variant="outlined"
                    fullWidth
                    required
                  />
                  <TextField
                    label="Message"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    required
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    size="large">
                    Send Message
                  </Button>
                </Stack>
              </form>
            </Paper>
          </Grid>

          {/* Contact Information */}
          <Grid item xs={12} md={5}>
            <Paper
              elevation={4}
              sx={{
                p: 4,
                borderRadius: 2,
                bgcolor: "primary.main",
                color: "white",
              }}>
              <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
                Contact Information
              </Typography>
              <Typography variant="body1" sx={{ mb: 4 }}>
                Reach us via the following channels. We’re here to assist you.
              </Typography>
              <Stack spacing={3}>
                <Box display="flex" alignItems="center">
                  <Email sx={{ mr: 2 }} />
                  <Typography>support@ourmarketplace.com</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Phone sx={{ mr: 2 }} />
                  <Typography>+1 234 567 890</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <LocationOn sx={{ mr: 2 }} />
                  <Typography>123 Giving Lane, Kindness City, Earth</Typography>
                </Box>
              </Stack>
              <Typography sx={{ mt: 4 }}>
                <strong>Working Hours:</strong> Monday - Friday: 9 AM - 6 PM
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ContactUs;

import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
} from "@mui/material";

const ItemCard = ({ item, onViewDetails }) => {
  const parseImageUrl = (imageUrl) => {
    try {
      const urls = JSON.parse(imageUrl);
      return urls[0];
    } catch {
      return "/placeholder.jpg"; // Fallback
    }
  };

  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        padding: 2,
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        "&:hover": {
          transform: "scale(1.05)",
          boxShadow: (theme) =>
            theme.shadows[6] || "0px 4px 6px rgba(0, 0, 0, 0.1)", // Fallback shadow
        },
      }}>
      <CardMedia
        component="img"
        height="300"
        image={parseImageUrl(item.image_url)}
        alt={item.title}
        sx={{ objectFit: "cover", borderRadius: 1 }}
      />
      <CardContent>
        <Typography variant="h5" gutterBottom>
          {item.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {item.description.substring(0, 100)}...
        </Typography>
        <Box sx={{ mt: 1 }}>
          <Typography variant="body2">
            <strong>Category:</strong> {item.category}
          </Typography>
          <Typography variant="body2">
            <strong>Condition:</strong> {item.condition}
          </Typography>
        </Box>
      </CardContent>
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: "auto" }}
        onClick={() => onViewDetails(item.id)}>
        View Item
      </Button>
    </Card>
  );
};

export default ItemCard;

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import ContactUs from "./pages/Contact";
import LoginPage from "./pages/auth/Login";
import Signup from "./pages/auth/Signup";
import ProtectedRoute from "./ProtectedRoute";
import Browse from "./pages/receiptient/Browse";
import Profile from "./pages/Profile";
import AuthProvider from "./context/AuthContext";
import AddItem from "./pages/donor/AddItem";
import DonorListings from "./pages/donor/DonorListings";
import Navbar from "./components/Navbar";
import { Footer } from "./components/Footer";
import CartPage from "./pages/receiptient/Cart";
import ItemDetails from "./pages/receiptient/ItemDetails";
import AdminDashboard from "./pages/admin/AdminDashboard";
import HistoryPage from "./pages/admin/History";
import AdminUserManagement from "./pages/admin/UserManagement";
import DriverManagement from "./pages/admin/DriverManagment";
import OrderManagement from "./pages/admin/OrderManagment";
import { Box } from "@mui/system";
import { supabase } from "./supabaseClient";

// function App() {
//   const [cart, setCart] = useState(() => {
//     const savedCart = localStorage.getItem("cart");
//     return savedCart ? JSON.parse(savedCart) : [];
//   });

//   const [activeCartId, setActiveCartId] = useState(null);

//   useEffect(() => {
//     localStorage.setItem("cart", JSON.stringify(cart));
//   }, [cart]);

//   const addToCart = async (itemDetails) => {
//     try {
//       // Get current user session
//       const { data: { session }, error: sessionError } = await supabase.auth.getSession();
//       if (sessionError) throw sessionError;

//       if (!session?.user?.id) {
//         throw new Error('User not authenticated');
//       }

//       const userId = session.user.id;
//       console.log('Current user ID:', userId);

//       // Check for existing active cart or create new one
//       let currentCartId = activeCartId;

//       if (!currentCartId) {
//         const { data: existingCart, error: cartError } = await supabase
//           .from('carts')
//           .select()
//           .eq('user_id', userId)
//           .eq('status', 'active')
//           .order('created_at', { ascending: false })
//           .limit(1);

//         if (cartError) throw cartError;

//         if (existingCart && existingCart.length > 0) {
//           currentCartId = existingCart[0].id;
//         } else {
//           // Create new cart
//           const { data: newCart, error: createError } = await supabase
//             .from('carts')
//             .insert({
//               user_id: userId,
//               status: 'active'
//             })
//             .select()
//             .single();

//           if (createError) throw createError;
//           currentCartId = newCart.id;
//         }

//         setActiveCartId(currentCartId);
//       }

//       // Create request in database
//       const { data: request, error: requestError } = await supabase
//         .from('requests')
//         .insert({
//           item_id: itemDetails.item_id,
//           buyer_id: userId,
//           donor_id: itemDetails.donor_id,
//           status: 'pending',
//           quantity: itemDetails.quantity || 1,
//           cart_id: currentCartId
//         })
//         .select()
//         .single();

//       if (requestError) throw requestError;

//       // Update local cart state
//       if (!cart.find((cartItem) => cartItem.item_id === itemDetails.item_id)) {
//         setCart((prev) => [...prev, {
//           ...itemDetails,
//           cart_id: currentCartId,
//           request_id: request.id,
//           quantity: itemDetails.quantity || 1
//         }]);
//         return true;
//       }

//       return false;
//     } catch (error) {
//       console.error('Error adding to cart:', error);
//       throw error;
//     }
//   };

//   const removeFromCart = async (itemId) => {
//     try {
//       // Remove from database first
//       if (activeCartId) {
//         const { error } = await supabase
//           .from('requests')
//           .delete()
//           .match({
//             cart_id: activeCartId,
//             item_id: itemId
//           });

//         if (error) throw error;
//       }

//       // Then remove from local state
//       setCart(prevCart => prevCart.filter(item => item.item_id !== itemId));
//     } catch (error) {
//       console.error('Error removing item from cart:', error);
//       alert('Failed to remove item from cart');
//     }
//   };

//   // Add function to fetch cart items on app load
//   const fetchCartItems = async () => {
//     try {
//       const { data: { session } } = await supabase.auth.getSession();
//       if (!session?.user?.id) return;

//       // Get active cart
//       const { data: cartData, error: cartError } = await supabase
//         .from('carts')
//         .select()
//         .eq('user_id', session.user.id)
//         .eq('status', 'active')
//         .order('created_at', { ascending: false })
//         .limit(1);

//       if (cartError) throw cartError;

//       if (cartData && cartData.length > 0) {
//         const currentCart = cartData[0];
//         setActiveCartId(currentCart.id);

//         // Fetch requests for this cart
//         const { data: requests, error: requestError } = await supabase
//           .from('requests')
//           .select(`
//             *,
//             items (*)
//           `)
//           .eq('cart_id', currentCart.id)
//           .eq('status', 'pending');

//         if (requestError) throw requestError;

//         // Transform requests into cart items
//         const cartItems = requests.map(request => ({
//           item_id: request.item_id,
//           cart_id: request.cart_id,
//           request_id: request.id,
//           quantity: request.quantity,
//           donor_id: request.donor_id,
//           ...request.items
//         }));

//         setCart(cartItems);
//       }
//     } catch (error) {
//       console.error('Error fetching cart:', error);
//     }
//   };

//   // Use useEffect to fetch cart items on app load
//   useEffect(() => {
//     fetchCartItems();
//   }, []);

//   const clearCart = () => {
//     setCart([]);
//     setActiveCartId(null);
//     localStorage.removeItem("cart");
//   };

//   return (
//     <Router>
//       <AuthProvider>
//         <Box
//           sx={{
//             display: "flex",
//             flexDirection: "column",
//             minHeight: "100vh",
//           }}>
//           <Navbar cartCount={cart.length} />
//           <Box sx={{ flex: 1 }}>
//             <Routes>
//               <Route path="/" element={<Home />} />
//               <Route path="/login" element={<LoginPage />} />
//               <Route path="/signup" element={<Signup />} />
//               <Route path="/contact" element={<ContactUs />} />
//               <Route
//                 path="/browse"
//                 element={
//                   <ProtectedRoute allowedRoles={["admin", "buyer"]}>
//                     <Browse />
//                   </ProtectedRoute>
//                 }
//               />
//               <Route
//                 path="/profile"
//                 element={
//                   <ProtectedRoute>
//                     <Profile />
//                   </ProtectedRoute>
//                 }
//               />
//               <Route
//                 path="/add-item"
//                 element={
//                   <ProtectedRoute allowedRoles={["donor"]}>
//                     <AddItem />
//                   </ProtectedRoute>
//                 }
//               />
//               <Route
//                 path="/donor-listings"
//                 element={
//                   <ProtectedRoute allowedRoles={["donor"]}>
//                     <DonorListings />
//                   </ProtectedRoute>
//                 }
//               />
//               <Route
//                 path="/cart"
//                 element={
//                   <ProtectedRoute allowedRoles={["buyer"]}>
//                     <CartPage
//                       cart={cart}
//                       setCart={setCart}
//                       removeFromCart={removeFromCart}
//                       clearCart={clearCart}
//                       activeCartId={activeCartId}
//                       setActiveCartId={setActiveCartId}
//                     />
//                   </ProtectedRoute>
//                 }
//               />
//               <Route
//                 path="/item/:id"
//                 element={
//                   <ProtectedRoute allowedRoles={["buyer"]}>
//                     <ItemDetails addToCart={addToCart} />
//                   </ProtectedRoute>
//                 }
//               />
//               <Route
//                 path="/admin-dashboard"
//                 element={
//                   <ProtectedRoute allowedRoles={["admin"]}>
//                     <AdminDashboard />
//                   </ProtectedRoute>
//                 }
//               />
//               <Route
//                 path="/history"
//                 element={
//                   <ProtectedRoute allowedRoles={["admin"]}>
//                     <HistoryPage />
//                   </ProtectedRoute>
//                 }
//               />
//               <Route
//                 path="/admin/users"
//                 element={
//                   <ProtectedRoute allowedRoles={["admin"]}>
//                     <AdminUserManagement />
//                   </ProtectedRoute>
//                 }
//               />
//               <Route
//                 path="/admin/drivers"
//                 element={
//                   <ProtectedRoute allowedRoles={["admin"]}>
//                     <DriverManagement />
//                   </ProtectedRoute>
//                 }
//               />
//               <Route
//                 path="/admin/orders"
//                 element={
//                   <ProtectedRoute allowedRoles={["admin"]}>
//                     <OrderManagement />
//                   </ProtectedRoute>
//                 }
//               />
//             </Routes>
//           </Box>
//           <Footer />
//         </Box>
//       </AuthProvider>
//     </Router>
//   );
// }

// export default App;
function App() {
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem("cart");
    return savedCart ? JSON.parse(savedCart) : [];
  });

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  const addToCart = (itemDetails) => {
    // Check if item is already in cart
    const existingItemIndex = cart.findIndex(
      (ci) => ci.item_id === itemDetails.item_id
    );
    if (existingItemIndex > -1) {
      // If already in cart, just increment quantity
      const updatedCart = [...cart];
      updatedCart[existingItemIndex].quantity += itemDetails.quantity || 1;
      setCart(updatedCart);
    } else {
      // Add new item to cart
      setCart((prev) => [
        ...prev,
        {
          ...itemDetails,
          quantity: itemDetails.quantity || 1,
        },
      ]);
    }
  };

  const changeCartItemQuantity = (itemId, delta) => {
    const updatedCart = cart
      .map((item) => {
        if (item.item_id === itemId) {
          return {
            ...item,
            quantity: item.quantity + delta,
          };
        }
        return item;
      })
      .filter((item) => item.quantity > 0);

    setCart(updatedCart);
  };

  const removeFromCart = (itemId) => {
    // Remove all quantities of that item from cart
    setCart((prevCart) => prevCart.filter((item) => item.item_id !== itemId));
  };

  const clearCart = () => {
    setCart([]);
    localStorage.removeItem("cart");
  };

  return (
    <Router>
      <AuthProvider>
        <Box
          sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
          <Navbar
            cartCount={cart.reduce((acc, item) => acc + item.quantity, 0)}
          />
          <Box sx={{ flex: 1 }}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/contact" element={<ContactUs />} />
              <Route
                path="/browse"
                element={
                  <ProtectedRoute allowedRoles={["admin", "buyer"]}>
                    <Browse />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/add-item"
                element={
                  <ProtectedRoute allowedRoles={["donor"]}>
                    <AddItem />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/donor-listings"
                element={
                  <ProtectedRoute allowedRoles={["donor"]}>
                    <DonorListings />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/cart"
                element={
                  <ProtectedRoute allowedRoles={["buyer"]}>
                    <CartPage
                      cart={cart}
                      setCart={setCart}
                      removeFromCart={removeFromCart}
                      clearCart={clearCart}
                      changeCartItemQuantity={changeCartItemQuantity}
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/item/:id"
                element={
                  <ProtectedRoute allowedRoles={["buyer"]}>
                    <ItemDetails addToCart={addToCart} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/dashboard"
                element={
                  <ProtectedRoute allowedRoles={["admin"]}>
                    <AdminDashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/history"
                element={
                  <ProtectedRoute allowedRoles={["admin"]}>
                    <HistoryPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/users"
                element={
                  <ProtectedRoute allowedRoles={["admin"]}>
                    <AdminUserManagement />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/drivers"
                element={
                  <ProtectedRoute allowedRoles={["admin"]}>
                    <DriverManagement />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/orders"
                element={
                  <ProtectedRoute allowedRoles={["admin"]}>
                    <OrderManagement />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </Box>
          <Footer />
        </Box>
      </AuthProvider>
    </Router>
  );
}

export default App;

import React, { useContext, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Link as MuiLink,
  Badge,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Button,
  Divider,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { AuthContext } from "../context/AuthContext";

const Navbar = ({ cartCount }) => {
  const { user, role } = useContext(AuthContext);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  // Define links for each role
  const roleBasedLinks = {
    admin: [
      { label: "Dashboard", path: "/admin/dashboard" },
      { label: "Order Management", path: "/admin/orders" },

      { label: "Manage Users", path: "/admin/users" },
      { label: "History", path: "/history" },
      { label: "Driver Management", path: "/admin/drivers" },
    ],
    donor: [
      { label: "My Donations", path: "/donor-listings" },
      { label: "Post Item", path: "/add-item" },
    ],
    buyer: [{ label: "Browse", path: "/browse" }],
  };

  const commonLinks = [
    { label: "Home", path: "/" },
    { label: "Contact", path: "/contact" },
  ];

  return (
    <AppBar
      position="static"
      sx={{ bgcolor: "white", color: "gray", boxShadow: 1 }}>
      <Toolbar sx={{ justifyContent: "space-between", px: { xs: 2, md: 4 } }}>
        {/* Logo */}
        <MuiLink
          href="/"
          underline="none"
          sx={{
            display: "flex",
            alignItems: "center",
            color: "inherit",
          }}>
          <FavoriteIcon sx={{ color: "#f43f5e", fontSize: 32, mr: 1 }} />
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{ color: "gray", "&:hover": { color: "black" } }}>
            GiveShare
          </Typography>
        </MuiLink>

        {/* Desktop Navigation */}
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            alignItems: "center",
            gap: 3,
          }}>
          {commonLinks.map((link) => (
            <MuiLink
              key={link.path}
              href={link.path}
              underline="none"
              sx={{
                color: "gray",
                fontWeight: "bold",
                "&:hover": { color: "black" },
              }}>
              {link.label}
            </MuiLink>
          ))}

          {!user ? (
            <Button
              href="/login"
              variant="contained"
              sx={{
                bgcolor: "#f43f5e",
                color: "white",
                textTransform: "none",
                "&:hover": { bgcolor: "#e11d48" },
              }}>
              Sign In
            </Button>
          ) : (
            <MuiLink
              href="/profile"
              underline="none"
              sx={{
                color: "gray",
                fontWeight: "bold",
                "&:hover": { color: "black" },
              }}>
              Profile
            </MuiLink>
          )}

          {role &&
            roleBasedLinks[role]?.map((link) => (
              <MuiLink
                key={link.path}
                href={link.path}
                underline="none"
                sx={{
                  color: "gray",
                  fontWeight: "bold",
                  "&:hover": { color: "black" },
                }}>
                {link.label}
              </MuiLink>
            ))}

          {role === "buyer" && (
            <MuiLink
              href="/cart"
              underline="none"
              sx={{
                display: "flex",
                alignItems: "center",
                color: "gray",
                "&:hover": { color: "black" },
              }}>
              <Badge badgeContent={cartCount} color="secondary">
                <ShoppingCartIcon />
              </Badge>
            </MuiLink>
          )}
        </Box>

        {/* Mobile Menu Button */}
        <IconButton
          onClick={() => setMobileMenuOpen(true)}
          sx={{ display: { xs: "flex", md: "none" }, color: "gray" }}>
          <MenuIcon />
        </IconButton>
      </Toolbar>

      {/* Mobile Navigation Drawer */}
      <Drawer
        anchor="left"
        open={isMobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}>
        <Box sx={{ width: 250 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 2,
              bgcolor: "#f9fafb",
            }}>
            <Typography variant="h6" fontWeight="bold">
              GiveShare
            </Typography>
            <IconButton onClick={() => setMobileMenuOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider />
          <List>
            {commonLinks.map((link) => (
              <ListItem button key={link.path} component="a" href={link.path}>
                <ListItemText primary={link.label} />
              </ListItem>
            ))}
            {role &&
              roleBasedLinks[role]?.map((link) => (
                <ListItem button key={link.path} component="a" href={link.path}>
                  <ListItemText primary={link.label} />
                </ListItem>
              ))}
            {role === "buyer" && (
              <ListItem button component="a" href="/cart">
                <ListItemText primary="Cart" />
                <Badge badgeContent={cartCount} color="secondary" />
              </ListItem>
            )}
            {!user ? (
              <ListItem button component="a" href="/login">
                <ListItemText primary="Sign In" />
              </ListItem>
            ) : (
              <ListItem button component="a" href="/profile">
                <ListItemText primary="Profile" />
              </ListItem>
            )}
          </List>
        </Box>
      </Drawer>
    </AppBar>
  );
};

export default Navbar;

// import { useContext, useEffect, useState } from "react";
// import { AuthContext } from "./context/AuthContext";
// import { Navigate } from "react-router-dom";
// import CircularProgress from "@mui/material/CircularProgress";
// import Box from "@mui/material/Box";
// import { supabase } from "./supabaseClient";

// const ProtectedRoute = ({ children, allowedRoles }) => {
//   const { user, role } = useContext(AuthContext);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const checkSession = async () => {
//       const session = await supabase.auth.getSession();
//       if (session.data?.session) {
//         setLoading(false);
//       } else {
//         setLoading(false);
//       }
//     };

//     if (user === null) {
//       checkSession();
//     } else {
//       setLoading(false);
//     }
//   }, [user]);

//   if (loading) {
//     return (
//       <Box
//         display="flex"
//         justifyContent="center"
//         alignItems="center"
//         height="100vh">
//         <CircularProgress />
//       </Box>
//     );
//   }

//   if (!user) {
//     return <Navigate to="/login" />;
//   }

//   if (allowedRoles && !allowedRoles.includes(role)) {
//     return <Navigate to="/" />;
//   }

//   return children;
// };

// export default ProtectedRoute;

import { useAuth } from "./context/AuthContext";
import { Navigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const ProtectedRoute = ({ children, allowedRoles }) => {
  const { user, role, session } = useAuth();

  if (!session) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!user || (allowedRoles && !allowedRoles.includes(role))) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;

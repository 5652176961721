import React, { createContext, useContext, useState, useEffect } from "react";
import { supabase } from "../supabaseClient";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [session, setSession] = useState(null);
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const { data: sessionData } = await supabase.auth.getSession();

        if (sessionData?.session) {
          setSession(sessionData.session);
          setUser(sessionData.session.user);

          // Fetch role for the logged-in user
          const { data: userInfo, error } = await supabase
            .from("users")
            .select("role")
            .eq("id", sessionData.session.user.id)
            .single();

          if (error) {
            console.error("Error fetching user role:", error.message);
          } else {
            setRole(userInfo?.role);
          }
        } else {
          setSession(null);
          setUser(null);
          setRole(null);
        }
      } catch (error) {
        console.error("Error initializing session:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSession();

    // Listen for changes in auth state
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      setSession(session);
      setUser(session?.user ?? null);
      if (session) {
        fetchSession(); // Re-fetch user role if session changes
      } else {
        setRole(null);
      }
    });
    return () => {
      subscription?.unsubscribe();
    };
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show loading spinner while session initializes
  }

  return (
    <AuthContext.Provider value={{ session, user, role }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

// Hook to use AuthContext
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

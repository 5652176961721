import React, { useState, useEffect } from "react";
import { supabase } from "../../supabaseClient";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Snackbar,
  Alert,
  Chip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const DonorListings = () => {
  const [listings, setListings] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const fetchListings = async () => {
    try {
      const { data: sessionData, error: sessionError } =
        await supabase.auth.getSession();

      if (sessionError) throw sessionError;

      const userId = sessionData.session?.user?.id;

      if (!userId) {
        throw new Error("User is not authenticated.");
      }

      const { data, error } = await supabase
        .from("items")
        .select("*")
        .eq("donor_id", userId);
      console.log(data);

      if (error) throw error;

      setListings(data);
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    fetchListings();
  }, []);

  const handleDelete = async (id) => {
    try {
      const { error } = await supabase.from("items").delete().eq("id", id);

      if (error) throw error;

      setListings(listings.filter((item) => item.id !== id));
      setSuccess(true);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    setOpenEditDialog(true);
  };

  const handleUpdate = async () => {
    try {
      const {
        id,
        title,
        description,
        category,
        size,
        condition,
        location,
        status,
      } = selectedItem;

      const { error } = await supabase
        .from("items")
        .update({
          title,
          description,
          category,
          size,
          condition,
          location,
          status,
        })
        .eq("id", id);

      if (error) throw error;

      setListings(
        listings.map((item) =>
          item.id === id ? { ...item, ...selectedItem } : item
        )
      );
      setOpenEditDialog(false);
      setSuccess(true);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleClose = () => {
    setOpenEditDialog(false);
    setSelectedItem(null);
  };

  const getStatusLabel = (status, stock) => {
    if (stock === 0) {
      return <Chip label="Out of Stock" color="error" />;
    }
    switch (status) {
      case "available":
        return <Chip label={`Available (${stock} items)`} color="success" />;
      case "requested":
        return <Chip label="Requested" color="warning" />;
      case "delivered":
        return <Chip label="Delivered" color="info" />;
      default:
        return <Chip label="Unknown" color="default" />;
    }
  };

  const getStatusMessage = (status, stock) => {
    if (stock === 0) {
      return "This item is currently out of stock.";
    }
    switch (status) {
      case "available":
        return `This item is currently available (${stock} items in stock).`;
      case "requested":
        return "A recipient has requested this item. Our team will coordinate the delivery.";
      case "delivered":
        return "This item has been delivered.";
      default:
        return "Status unknown.";
    }
  };

  const parseImageUrl = (imageUrl) => {
    try {
      const urls = JSON.parse(imageUrl); // Parse the JSON string into an array
      return urls[0]; // Return the first image URL
    } catch {
      return "/placeholder.jpg"; // Fallback in case of error
    }
  };

  return (
    <Box sx={{ maxWidth: 1200, mx: "auto", mt: 4, p: 3 }}>
      <Typography variant="h4" align="center" fontWeight="bold" gutterBottom>
        My Listings
      </Typography>

      <Grid container spacing={3}>
        {listings.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item.id}>
            <Card>
              <CardMedia
                component="img"
                height="140"
                image={parseImageUrl(item.image_url)} // Parse and use the first image URL
                alt={item.title}
              />
              <CardContent>
                <Typography variant="h6">{item.title}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {item.description}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Category: {item.category}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Condition: {item.condition}
                </Typography>
                <Box sx={{ mt: 2 }}>{getStatusLabel(item.status, item.stock)}</Box>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mt: 1 }}>
                  {getStatusMessage(item.status, item.stock)}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Stock: {item.stock}
                </Typography>
              </CardContent>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
                <Button
                  variant="outlined"
                  startIcon={<EditIcon />}
                  onClick={() => handleEdit(item)}>
                  Edit
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={() => handleDelete(item.id)}>
                  Delete
                </Button>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Edit Dialog */}
      {selectedItem && (
        <Dialog open={openEditDialog} onClose={handleClose}>
          <DialogTitle>Edit Item</DialogTitle>
          <DialogContent>
            <TextField
              label="Title"
              name="title"
              value={selectedItem.title}
              onChange={(e) =>
                setSelectedItem({ ...selectedItem, title: e.target.value })
              }
              fullWidth
              margin="dense"
              variant="outlined"
            />
            <TextField
              label="Description"
              name="description"
              value={selectedItem.description}
              onChange={(e) =>
                setSelectedItem({
                  ...selectedItem,
                  description: e.target.value,
                })
              }
              multiline
              rows={4}
              fullWidth
              margin="dense"
              variant="outlined"
            />
            <TextField
              label="Category"
              name="category"
              value={selectedItem.category}
              onChange={(e) =>
                setSelectedItem({ ...selectedItem, category: e.target.value })
              }
              select
              fullWidth
              margin="dense"
              variant="outlined">
              <MenuItem value="clothing">Clothing</MenuItem>
              <MenuItem value="furniture">Furniture</MenuItem>
              <MenuItem value="electronics">Electronics</MenuItem>
              <MenuItem value="others">Others</MenuItem>
            </TextField>
            <TextField
              label="Condition"
              name="condition"
              value={selectedItem.condition}
              onChange={(e) =>
                setSelectedItem({ ...selectedItem, condition: e.target.value })
              }
              select
              fullWidth
              margin="dense"
              variant="outlined">
              <MenuItem value="new">New</MenuItem>
              <MenuItem value="used-good">Used - Good</MenuItem>
              <MenuItem value="used-fair">Used - Fair</MenuItem>
            </TextField>
            <TextField
              label="Location"
              name="location"
              value={selectedItem.location}
              onChange={(e) =>
                setSelectedItem({ ...selectedItem, location: e.target.value })
              }
              fullWidth
              margin="dense"
              variant="outlined"
            />
            <TextField
              label="Status"
              name="status"
              value={selectedItem.status}
              onChange={(e) =>
                setSelectedItem({ ...selectedItem, status: e.target.value })
              }
              select
              fullWidth
              margin="dense"
              variant="outlined">
              <MenuItem value="available">Available</MenuItem>
              <MenuItem value="requested">Requested</MenuItem>
              <MenuItem value="delivered">Delivered</MenuItem>
            </TextField>
            <TextField
              label="Stock"
              name="stock"
              type="number"
              value={selectedItem.stock}
              onChange={(e) =>
                setSelectedItem({ 
                  ...selectedItem, 
                  stock: parseInt(e.target.value),
                  // Automatically update status based on stock
                  status: parseInt(e.target.value) > 0 ? 'available' : 'out_of_stock'
                })
              }
              fullWidth
              margin="dense"
              variant="outlined"
              InputProps={{ inputProps: { min: 0 } }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleUpdate} variant="contained" color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Snackbar Notifications */}
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}>
        <Alert severity="success" sx={{ width: "100%" }}>
          Action completed successfully!
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError("")}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default DonorListings;

import { AboutUsSection } from "../components/Home/AboutUs";
import { FeaturedItemsSection } from "../components/Home/Featured";
import { HowItWorksSection } from "../components/Home/HowItWorks";
import { HeroSection } from "../components/Home/Hero";

export default function Home() {
  return (
    <div>
      <HeroSection />
      <FeaturedItemsSection />
      <HowItWorksSection />
      <AboutUsSection />
    </div>
  );
}

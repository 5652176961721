import { Box, Container, Typography } from "@mui/material";
export function Footer() {
  return (
    <Box
      sx={{
        py: 4,
        backgroundColor: "#333",
        color: "white",
        textAlign: "center",
      }}>
      <Container maxWidth="lg">
        <Typography variant="body2">
          © 2023 Your Marketplace. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
}

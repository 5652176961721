import React, { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { Typography, Box, Button } from "@mui/material";
import { supabase } from "../supabaseClient";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const { user, role } = useContext(AuthContext);
  const navigate = useNavigate();

  //   logout handler supabase
  const handleLogout = async () => {
    await supabase.auth.signOut();
    navigate("/");
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4">Profile</Typography>
      <Typography variant="body1">Email: {user?.email}</Typography>
      <Typography variant="body1">Role: {role}</Typography>
      <Button onClick={handleLogout}>Logout</Button>
    </Box>
    // button to logout
  );
};

export default Profile;

import React, { useState, useEffect } from "react";
import { supabase } from "../../supabaseClient";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Snackbar,
  Alert,
  Pagination,
} from "@mui/material";
import ItemCard from "../../components/buyer/ItemCard"; // Import the new ItemCard component
import { useNavigate } from "react-router-dom"; // Import useNavigate

// const BrowsePage = () => {
//   const [listings, setListings] = useState([]);
//   const [filteredItems, setFilteredItems] = useState([]);
//   const [displayedItems, setDisplayedItems] = useState([]);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [categoryFilter, setCategoryFilter] = useState("");
//   const [conditionFilter, setConditionFilter] = useState("");
//   const [currentPage, setCurrentPage] = useState(1);
//   const [error, setError] = useState("");

//   const ITEMS_PER_PAGE = 12; // Pagination limit
//   const navigate = useNavigate(); // Initialize navigate

//   const fetchListings = async () => {
//     try {
//       const { data, error } = await supabase
//         .from("items")
//         .select("*")
//         .eq("status", "available")
//         .gt("stock", 0);

//       if (error) throw error;

//       setListings(data);
//       setFilteredItems(data);
//       paginate(data, 1);
//     } catch (err) {
//       setError(err.message);
//     }
//   };

//   useEffect(() => {
//     fetchListings();
//   }, []);

//   const handleSearch = (e) => {
//     const term = e.target.value.toLowerCase();
//     setSearchTerm(term);

//     const filtered = listings.filter(
//       (item) =>
//         item.title.toLowerCase().includes(term) ||
//         item.description.toLowerCase().includes(term)
//     );

//     applyFilters(filtered);
//   };

//   const applyFilters = (items) => {
//     let filtered = items || listings;

//     if (categoryFilter) {
//       filtered = filtered.filter((item) => item.category === categoryFilter);
//     }

//     if (conditionFilter) {
//       filtered = filtered.filter((item) => item.condition === conditionFilter);
//     }

//     setFilteredItems(filtered);
//     paginate(filtered, 1);
//   };

//   const handleCategoryChange = (e) => {
//     setCategoryFilter(e.target.value);
//     applyFilters();
//   };

//   const handleConditionChange = (e) => {
//     setConditionFilter(e.target.value);
//     applyFilters();
//   };

//   const paginate = (items, pageNumber) => {
//     const startIndex = (pageNumber - 1) * ITEMS_PER_PAGE;
//     const endIndex = startIndex + ITEMS_PER_PAGE;
//     setDisplayedItems(items.slice(startIndex, endIndex));
//     setCurrentPage(pageNumber);
//   };

//   const handlePageChange = (event, value) => {
//     paginate(filteredItems, value);
//   };

//   const handleViewDetails = (id) => {
//     navigate(`/item/${id}`); // Navigate to ItemDetails page
//   };

//   return (
//     <Box sx={{ mx: "auto", mt: 4, p: 3, m: 2 }}>
//       <Typography variant="h4" align="center" fontWeight="bold" gutterBottom>
//         Browse Listings
//       </Typography>

//       {/* Search and Filter Options */}
//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: "center",
//           alignContent: "center",
//           mb: 3,
//           gap: 2,
//         }}>
//         <TextField
//           label="Search items..."
//           variant="outlined"
//           value={searchTerm}
//           onChange={handleSearch}
//           fullWidth
//         />
//         <FormControl fullWidth>
//           <InputLabel>Category</InputLabel>
//           <Select
//             value={categoryFilter}
//             onChange={handleCategoryChange}
//             label="Category">
//             <MenuItem value="">All Categories</MenuItem>
//             <MenuItem value="clothing">Clothing</MenuItem>
//             <MenuItem value="furniture">Furniture</MenuItem>
//             <MenuItem value="electronics">Electronics</MenuItem>
//             <MenuItem value="others">Others</MenuItem>
//           </Select>
//         </FormControl>
//         <FormControl fullWidth>
//           <InputLabel>Condition</InputLabel>
//           <Select
//             value={conditionFilter}
//             onChange={handleConditionChange}
//             label="Condition">
//             <MenuItem value="">All Conditions</MenuItem>
//             <MenuItem value="new">New</MenuItem>
//             <MenuItem value="used-good">Used - Good</MenuItem>
//             <MenuItem value="used-fair">Used - Fair</MenuItem>
//           </Select>
//         </FormControl>
//       </Box>

//       {/* Listings Grid */}
//       <Grid container spacing={5}>
//         {displayedItems.map((item) => (
//           <Grid item xs={12} sm={6} md={4} lg={3} key={item.id} mt={2}>
//             <ItemCard item={item} onViewDetails={handleViewDetails} />
//           </Grid>
//         ))}
//       </Grid>

//       {/* Pagination */}
//       <Box sx={{ display: "flex", justifyContent: "center", mt: 8 }}>
//         <Pagination
//           count={Math.ceil(filteredItems.length / ITEMS_PER_PAGE)}
//           page={currentPage}
//           onChange={handlePageChange}
//           color="primary"
//         />
//       </Box>

//       {/* Snackbar Notifications */}
//       <Snackbar
//         open={!!error}
//         autoHideDuration={6000}
//         onClose={() => setError("")}>
//         <Alert severity="error" sx={{ width: "100%" }}>
//           {error}
//         </Alert>
//       </Snackbar>
//     </Box>
//   );
// };

// export default BrowsePage;

const BrowsePage = () => {
  const [listings, setListings] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [displayedItems, setDisplayedItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [conditionFilter, setConditionFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState("");

  const ITEMS_PER_PAGE = 12;
  const navigate = useNavigate();

  const fetchListings = async () => {
    try {
      const { data, error } = await supabase
        .from("items")
        .select("*")
        .eq("status", "available")
        .gt("stock", 0);

      if (error) throw error;
      setListings(data);
      setFilteredItems(data);
      paginate(data, 1);
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    fetchListings();
  }, []);

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    const filtered = listings.filter(
      (item) =>
        item.title.toLowerCase().includes(term) ||
        item.description.toLowerCase().includes(term)
    );
    applyFilters(filtered);
  };

  const applyFilters = (items) => {
    let filtered = items || listings;

    if (categoryFilter) {
      filtered = filtered.filter((item) => item.category === categoryFilter);
    }

    if (conditionFilter) {
      filtered = filtered.filter((item) => item.condition === conditionFilter);
    }

    setFilteredItems(filtered);
    paginate(filtered, 1);
  };

  const handleCategoryChange = (e) => {
    setCategoryFilter(e.target.value);
    applyFilters();
  };

  const handleConditionChange = (e) => {
    setConditionFilter(e.target.value);
    applyFilters();
  };

  const paginate = (items, pageNumber) => {
    const startIndex = (pageNumber - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    setDisplayedItems(items.slice(startIndex, endIndex));
    setCurrentPage(pageNumber);
  };

  const handlePageChange = (event, value) => {
    paginate(filteredItems, value);
  };

  const handleViewDetails = (id) => {
    navigate(`/item/${id}`);
  };

  return (
    <Box sx={{ mx: "auto", mt: 4, p: 3, m: 2 }}>
      <Typography variant="h4" align="center" fontWeight="bold" gutterBottom>
        Browse Listings
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          mb: 3,
          gap: 2,
        }}>
        <TextField
          label="Search items..."
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
          fullWidth
        />
        <FormControl fullWidth>
          <InputLabel>Category</InputLabel>
          <Select
            value={categoryFilter}
            onChange={handleCategoryChange}
            label="Category">
            <MenuItem value="">All Categories</MenuItem>
            <MenuItem value="clothing">Clothing</MenuItem>
            <MenuItem value="furniture">Furniture</MenuItem>
            <MenuItem value="electronics">Electronics</MenuItem>
            <MenuItem value="others">Others</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>Condition</InputLabel>
          <Select
            value={conditionFilter}
            onChange={handleConditionChange}
            label="Condition">
            <MenuItem value="">All Conditions</MenuItem>
            <MenuItem value="new">New</MenuItem>
            <MenuItem value="used-good">Used - Good</MenuItem>
            <MenuItem value="used-fair">Used - Fair</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Grid container spacing={5}>
        {displayedItems.map((item) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={item.id} mt={2}>
            <ItemCard item={item} onViewDetails={handleViewDetails} />
          </Grid>
        ))}
      </Grid>

      <Box sx={{ display: "flex", justifyContent: "center", mt: 8 }}>
        <Pagination
          count={Math.ceil(filteredItems.length / ITEMS_PER_PAGE)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError("")}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default BrowsePage;
